
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref } from "vue"
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import SendToSaleModal from "./modals/SendToSaleModal.vue";
import TableSaleQuoteModule from "@/store/modules/sales/modules/tablequote/index" 
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import NewSaleModule from "@/store/modules/sales/modules/new-sales";
import { formatToDatatable } from "@/common/formaterToDatatable";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { FormTypes } from "@/core/enums/form-types";
import { getModule } from "vuex-module-decorators";
import { translate } from "@/core/plugins/i18n";
import { Field, Form } from "vee-validate";
import { useRouter } from "vue-router";
import * as Validations from "yup";
import TableSalesToBillModule from "@/store/modules/bill/modules/table-sales-to-bill";
import SaleTypeModule from "@/store/modules/saleType/modules/new-saletype";
import { debounce } from "lodash";
import PDFViewer from "@/components/modals/general/PDFViewer.vue";
import hotkeys from "hotkeys-js";

export default defineComponent({
    components:{
          Field
        , Form
        , ModuleMaster
        , Datatable
        , Datarow
        , PDFViewer
        , SendToSaleModal
    },
    setup() {
        //Propiedades
        const moduleTable = getModule(TableSaleQuoteModule);
        const moduleNewSale = getModule(NewSaleModule);
        const moduleSalesType = getModule(SaleTypeModule);
        const moduleTableSalesToBill = getModule(TableSalesToBillModule);
        const openModalSendToSale = ref(HTMLButtonElement);
        let modeForm = ref(FormTypes.Create);
        const router = useRouter();
        const formSearch = ref();
        const saleId = ref("");
        const openPDF = ref(HTMLButtonElement); 

        //Schema
        const validationSchema = Validations.object().shape({
            rfc: Validations.string().nullable().default(null).label("rfc"),
            folio: Validations.string().nullable().default(null).label("folio"),
            statusId: Validations.string().nullable().default("-1").label("statusId")
        });

        moduleTable.SEARCH_TABLE_DATA();
          
        /**
         * CONFIGURACIONES DE LA TABLA
         */
        const configTable = reactive([
            { PropName: "id", HeadLabel: "id", Type: "text", VisibleInGrid: false },
            { PropName: "saleQuoteFolio", HeadLabel: "Folio", Type: "text", VisibleInGrid: true },
            { PropName: "customer", HeadLabel: "Cliente", Type: "text", VisibleInGrid: true },
            { PropName: "fechaCreacion", HeadLabel: "Fecha", Type: "text", VisibleInGrid: true },
            { PropName: "saleQuoteExpiredAtF", HeadLabel: "Fecha de vencimiento", Type: "text", VisibleInGrid: true },
            { PropName: "total", HeadLabel: "Total", Type: "moneda", VisibleInGrid: true },
            { PropName: "agent", HeadLabel: "Vendedor", Type: "text", VisibleInGrid: true },            
            { PropName: "stage", HeadLabel: "Estatus", Type: "text", VisibleInGrid: true },            
        ]);

        const labels = reactive({
            placeHolderToFieldSearch: "Buscar cotización",
            titleNewCrudAction: "Nueva Cotización(ALT+N)",
        });

        //METODOS
        const filterNameChange = debounce((filterName: string) => {
            moduleTable.UPDATE_FILTERS({filterName});
        }, 550, {leading: false, trailing: true});

        const handleChangePage = (page: number) => {
            moduleTable.CHANGE_PAGE({ currentPage: page });
        }

        const handleSearchClient = (name: string) => {
          moduleTableSalesToBill.SEARCH_CLIENTS(name);
        }

        const handleCrudAction = (action: string | number = "N", id: string) => {
            moduleNewSale.RESET_NEW_SALE();
            moduleSalesType.SEARCH_SALES_TYPE({
                filterName: "",
                isTypeSaleQuote: action != "SV"
            });


            switch(action){
                case "SV":
                    handlerSenToSale(id);
                    break;
                case "N":
                    // setCurrentPageBreadcrumbs( translate('BREADCRUMB.NEWSALES'), [translate('BREADCRUMB.MODULES'), { name: translate('BREADCRUMB.SALES'), to: "sales" }]);
                    router.push({
                            name: "/sales/sale",
                            params: {
                                action: "newQuote"
                            }
                        });
                    break;
                case "M":
                    // setCurrentPageBreadcrumbs(translate('BREADCRUMB.SALES'), [translate('BREADCRUMB.MODULES')]);
                    moduleNewSale.SEARCH_INFO_SALE(id);
                    router.push({
                        name: "/sales/sale",
                        params: {
                            action: "editQuote"
                        }
                    });
                    break;
                case "V":
                    // setCurrentPageBreadcrumbs(translate('BREADCRUMB.SALES'), [translate('BREADCRUMB.MODULES')]);
                    moduleNewSale.SEARCH_INFO_SALE(id);
                    router.push({
                        name: "/sales/sale",
                        params: {
                            action: "viewQuote"
                        }
                    });
                    break;
                case "C":
                    handlerChangeStatus(id);
                    break;
                default:
                    break;
            }
        }

        const handlerSenToSale = (id: string) => {
            if(openModalSendToSale.value){
                saleId.value = id;
                (openModalSendToSale.value as unknown as HTMLButtonElement).click();
            }
        }

        const evitarRedireccion = (c) =>{
            let actionModule: Promise<boolean>;

            if(c.action == "a"){
                actionModule = moduleNewSale.GET_QUOTEVIE(
                    c.id,
                );

                actionModule.then((isCorrect2) => {
                    if(isCorrect2){
                        if(openPDF.value) {
                            (openPDF.value as unknown as HTMLButtonElement).click();
                        }
                    }
                });
            }else
            {
                actionModule = moduleNewSale.GET_QUOTEVIE_COMPLETE(
                    c.id,
                );

                actionModule.then((isCorrect2) => {
                    if(isCorrect2){
                        if(openPDF.value) {
                            (openPDF.value as unknown as HTMLButtonElement).click();
                        }
                    }
                });
            }
        }

        const handlerChangeStatus = (id: string) => {
             Swal.fire({
                        title: '¿Está seguro de cancelar la cotización?',
                        text: "Se procederá a cancelar la cotización para este pedido y no podrá revertir la acción",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#ffb300',
                        cancelButtonColor: '#7E829',
                        confirmButtonText: 'Si, cancelar',
                        cancelButtonText: 'Cancelar'
                        }).then((result) => {
                        if (result.isConfirmed) {
                            moduleNewSale.DISABLE_SALE_COTIZACION({
                                saleId: id,
                                observation: ''
                            });
                        }});
        }

        const searchFilter = (data: any) => {
            moduleTable.UPDATE_FILTERS({
                invoice: data.folio
                , rfc: data.rfc
                , status: data.statusId
            });
        }

        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(moduleTable.records, configTable);
        });

        const totalRecords = computed(() => moduleTable.totalRecords);
        const sizePage = computed(() => moduleTable.pagination.pageSize);
        const clientsCombo = computed(() => moduleTableSalesToBill.getClientOptions);
        const pdf = computed(() => moduleNewSale.getbase64PDF);
        
        onMounted(() => {
                setCurrentPageBreadcrumbs(translate('BREADCRUMB.SALESQUOTE'), [translate('BREADCRUMB.MODULES')]);
                formSearch.value.setValues({
                    statusId: "0"
                });

                hotkeys('alt+n', (event, handler) => {
                   handleCrudAction("N", '');
                });
        });

        onBeforeUnmount(() => {
                hotkeys.unbind('alt+n');
        });

        return {
            labels,
            headers,
            renderRows,
            totalRecords,
            sizePage,
            router
            , modeForm
            , validationSchema
            , formSearch
            , clientsCombo
            , openModalSendToSale
            , saleId
            , pdf
            , filterNameChange
            , openPDF

            , searchFilter
            , handleChangePage
            , handleCrudAction
            , handlerChangeStatus
            , handlerSenToSale
            , handleSearchClient
            , evitarRedireccion
        }
    }
})
